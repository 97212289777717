<template>
	<v-menu
		:close-on-content-click="false"
		offset-y
		v-show="$store.getters.getAlert === 0"
		@click="getAlerts"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon v-on="on" v-bind="attrs">
				<v-badge
					overlap
					:content="$store.getters.getAlerts.length"
					:value="$store.getters.getAlerts.length"
					color="error"
				>
					<v-icon ref="bell">mdi-bell</v-icon>
				</v-badge>
			</v-btn>
		</template>

		<v-list dense>
			<div class="d-flex mx-2">
				<v-subheader>Incidencias abiertas</v-subheader>
				<v-spacer />
				<v-btn small color="primary" @click="getAlerts">
					<v-icon left>mdi-reload</v-icon>recargar
				</v-btn>
			</div>
			<v-list-item-group>
				<v-list-item
					v-for="(alert, i) in $store.getters.getAlerts"
					:key="i"
					exact
					:to="`/incidencias?codigoContrato=${alert.codigoContrato
					}&idContrato=${alert.idContrato}&idIncidencia=${alert.id
					}&rand=${Math.random()}`"
				>
					<v-list-item-content>
						<span class="clip" v-html="alert.mensaje" />
						<div class="d-flex align-center">
							<v-icon
								v-if="
									$store.getters.getDecodedJwtEmpresa.idUsuario == alert.idUsuario
								"
								color="primary"
								small
							>mdi-account</v-icon>
							<span
								class="flex-grow-1 text-caption"
								v-text="new Date(alert.fecha * 1000).toLocaleString()"
							/>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-on="on"
										v-bind="attrs"
										link
										:to="`/contratos/detalles?codigoContrato=${alert.codigoContrato}&idContrato=${alert.idContrato}`"
										color="secondary"
										text
										small
									>{{ alert.codigoContrato }}</v-btn>
								</template>
								<span>Detalles del contrato</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-on="on"
										v-bind="attrs"
										color="error"
										icon
										exact
										small
										:to="`/incidencias?idContrato=${alert.idContrato}&codigoContrato=${alert.codigoContrato}`"
									>
										<v-icon v-if="$root.acceso('CREAR_INCIDENCIAS')" >mdi-plus-circle</v-icon>
										<v-icon v-else>mdi-alert-circle</v-icon>
									</v-btn>
								</template>
								<span>
									Ver{{ $root.acceso('CREAR_INCIDENCIAS') ? " / Crear" : "" }} Incidencias
								</span>
							</v-tooltip>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-menu>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
	data() {
		return {
			interval: null,
		};
	},
	methods: {
		parseDate,
		animationAndSound() {
			this.$refs.bell.$el.classList.add("animation");

			const audioCtx = new AudioContext();
			const audio = new Audio(require("@/assets/bellring.mp3"));
			audio.crossOrigin = "anonymous";
			const source = audioCtx.createMediaElementSource(audio);
			source.connect(audioCtx.destination);
			audio.play();
		},
		async getAlerts() {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdAgente } = str.iss;
			let { data: alerts } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/incidencias_alertas.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					idAgente: IdAgente,
				},
			});

			alerts = [
				...new Map(
					alerts
						.sort((a, b) => (a.fecha < b.fecha ? -1 : 1))
						.map((item) => [item.id, item])
				).values(),
			];

			if (this.$store.getters.getAlerts.length < alerts.length) {
				this.animationAndSound();
			}

			await this.$store.dispatch("setAlerts", alerts);
		},
	},
	mounted() {
		this.getAlerts();
		this.interval = setInterval(async () => await this.getAlerts(), 120 * 1000);

		this.$refs.bell.$el.addEventListener("transitioned", () =>
			this.$refs.bell.$el.classList.remove("animation")
		);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
};
</script>

<style scoped lang="scss">
.clip {
	text-align: justify;
	max-height: 120px;
	max-width: 400px;
	overflow-y: hidden;
}

.animation {
	animation: ring 4s 0.7s ease-in-out;
	transform-origin: 50% 4px;
}

@keyframes ring {
	0% {
		transform: rotate(0);
	}
	1% {
		transform: rotate(30deg);
	}
	3% {
		transform: rotate(-28deg);
	}
	5% {
		transform: rotate(34deg);
	}
	7% {
		transform: rotate(-32deg);
	}
	9% {
		transform: rotate(30deg);
	}
	11% {
		transform: rotate(-28deg);
	}
	13% {
		transform: rotate(26deg);
	}
	15% {
		transform: rotate(-24deg);
	}
	17% {
		transform: rotate(22deg);
	}
	19% {
		transform: rotate(-20deg);
	}
	21% {
		transform: rotate(18deg);
	}
	23% {
		transform: rotate(-16deg);
	}
	25% {
		transform: rotate(14deg);
	}
	27% {
		transform: rotate(-12deg);
	}
	29% {
		transform: rotate(10deg);
	}
	31% {
		transform: rotate(-8deg);
	}
	33% {
		transform: rotate(6deg);
	}
	35% {
		transform: rotate(-4deg);
	}
	37% {
		transform: rotate(2deg);
	}
	39% {
		transform: rotate(-1deg);
	}
	41% {
		transform: rotate(1deg);
	}
	43% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
</style>