var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.getAlert === 0),expression:"$store.getters.getAlert === 0"}],attrs:{"close-on-content-click":false,"offset-y":""},on:{"click":_vm.getAlerts},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"overlap":"","content":_vm.$store.getters.getAlerts.length,"value":_vm.$store.getters.getAlerts.length,"color":"error"}},[_c('v-icon',{ref:"bell"},[_vm._v("mdi-bell")])],1)],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('div',{staticClass:"d-flex mx-2"},[_c('v-subheader',[_vm._v("Incidencias abiertas")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.getAlerts}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("recargar ")],1)],1),_c('v-list-item-group',_vm._l((_vm.$store.getters.getAlerts),function(alert,i){return _c('v-list-item',{key:i,attrs:{"exact":"","to":("/incidencias?codigoContrato=" + (alert.codigoContrato) + "&idContrato=" + (alert.idContrato) + "&idIncidencia=" + (alert.id) + "&rand=" + (Math.random()))}},[_c('v-list-item-content',[_c('span',{staticClass:"clip",domProps:{"innerHTML":_vm._s(alert.mensaje)}}),_c('div',{staticClass:"d-flex align-center"},[(
								_vm.$store.getters.getDecodedJwtEmpresa.idUsuario == alert.idUsuario
							)?_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-account")]):_vm._e(),_c('span',{staticClass:"flex-grow-1 text-caption",domProps:{"textContent":_vm._s(new Date(alert.fecha * 1000).toLocaleString())}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/contratos/detalles?codigoContrato=" + (alert.codigoContrato) + "&idContrato=" + (alert.idContrato)),"color":"secondary","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(alert.codigoContrato))])]}}],null,true)},[_c('span',[_vm._v("Detalles del contrato")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","exact":"","small":"","to":("/incidencias?idContrato=" + (alert.idContrato) + "&codigoContrato=" + (alert.codigoContrato))}},'v-btn',attrs,false),on),[(_vm.$root.acceso('CREAR_INCIDENCIAS'))?_c('v-icon',[_vm._v("mdi-plus-circle")]):_c('v-icon',[_vm._v("mdi-alert-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(" Ver"+_vm._s(_vm.$root.acceso('CREAR_INCIDENCIAS') ? " / Crear" : "")+" Incidencias ")])])],1)])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }